var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"reports-card",attrs:{"elevation":"0","rounded":"","disabled":_vm.loading,"loading":_vm.loading}},[_c('v-card-title',{staticClass:"mb-2 pa-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-form',{ref:"form",staticClass:"px-4 pb-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"dense":"","label":"Destination store","outlined":"","required":"","append-icon":"mdi-store","clearable":""},model:{value:(_vm.orderReportForm.destinationStore),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "destinationStore", $$v)},expression:"orderReportForm.destinationStore"}}),_c('v-text-field',{attrs:{"dense":"","label":"Source store","outlined":"","required":"","append-icon":"mdi-store","clearable":""},model:{value:(_vm.orderReportForm.sourceStore),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "sourceStore", $$v)},expression:"orderReportForm.sourceStore"}}),_c('v-text-field',{attrs:{"dense":"","label":"Location","outlined":"","required":"","append-icon":"mdi-map","clearable":""},model:{value:(_vm.orderReportForm.location),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "location", $$v)},expression:"orderReportForm.location"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","readonly":"","append-icon":"mdi-calendar","dense":"","outlined":"","clearable":""},model:{value:(_vm.orderReportForm.startDate),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "startDate", $$v)},expression:"orderReportForm.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.orderReportForm.startDate),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "startDate", $$v)},expression:"orderReportForm.startDate"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","append-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","clearable":""},model:{value:(_vm.orderReportForm.endDate),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "endDate", $$v)},expression:"orderReportForm.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.orderReportForm.endDate),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "endDate", $$v)},expression:"orderReportForm.endDate"}})],1),_c('v-select',{attrs:{"dense":"","outlined":"","items":_vm.withSyncOptions,"item-text":"name","item-value":"id","label":"With Sync","clearable":""},model:{value:(_vm.orderReportForm.withSync),callback:function ($$v) {_vm.$set(_vm.orderReportForm, "withSync", $$v)},expression:"orderReportForm.withSync"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","elevation":"0","block":""},on:{"click":_vm.generateOrderReportsForLastMonth}},'v-btn',attrs,false),on),[_vm._v("Last Month Report")])]}}])},[_c('span',[_vm._v("Will generate report for last month")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","elevation":"0","block":"","disabled":_vm.disableGenerateButton},on:{"click":_vm.generateOrderReports}},'v-btn',attrs,false),on),[_vm._v("Custom Report")])],1)]}}])},[_c('span',[_vm._v("Use above fields to generate custom report")])])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.color,"timeout":_vm.timeout,"elevation":"0","top":"","tile":""},model:{value:(_vm.isSnackbarVisible),callback:function ($$v) {_vm.isSnackbarVisible=$$v},expression:"isSnackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }