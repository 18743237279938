<template>
  <section class="reports">
    <v-row>
      <v-col cols="6">
        <OrderReport title="Order Report" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import OrderReport from './OrderReport.vue';

export default {
  name: 'Reports',
  components: {
    OrderReport
  }
}
</script>
