<template>
  <v-card elevation="0" rounded :disabled="loading" :loading="loading" class="reports-card">
    <v-card-title class="mb-2 pa-4">
      {{ title }}
    </v-card-title>

    <v-form ref="form" v-model="valid" lazy-validation class="px-4 pb-4">
      <v-text-field dense label="Destination store" outlined required append-icon="mdi-store" v-model="orderReportForm.destinationStore" clearable></v-text-field>

      <v-text-field dense label="Source store" outlined required append-icon="mdi-store" v-model="orderReportForm.sourceStore" clearable></v-text-field>

      <v-text-field dense label="Location" outlined required append-icon="mdi-map" v-model="orderReportForm.location" clearable></v-text-field>

      <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="orderReportForm.startDate" label="Start Date" readonly append-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined clearable></v-text-field>
        </template>
        <v-date-picker v-model="orderReportForm.startDate" @input="startDateMenu = false"></v-date-picker>
      </v-menu>

      <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="orderReportForm.endDate" label="End Date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined clearable></v-text-field>
        </template>
        <v-date-picker v-model="orderReportForm.endDate" @input="endDateMenu = false"></v-date-picker>
      </v-menu>

      <v-select dense outlined v-model="orderReportForm.withSync" :items="withSyncOptions" item-text="name" item-value="id" label="With Sync" clearable />

      <v-row>
        <v-col cols="6">
          <v-tooltip bottom color="#000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" block @click="generateOrderReportsForLastMonth" class="text-capitalize">Last Month Report</v-btn>
            </template>
            <span>Will generate report for last month</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6">
          <v-tooltip bottom color="#000">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
                <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" block @click="generateOrderReports" :disabled="disableGenerateButton" class="text-capitalize">Custom Report</v-btn>
              </div>
            </template>
            <span>Use above fields to generate custom report</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="isSnackbarVisible" :color="color" :timeout="timeout" elevation="0" top tile>
      {{ snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'reports/getField',
  mutationType: 'reports/updateField',
});

export default {
  name: 'OrderReport',

  props: ['title'],

  data() {
    return {
      color: '',
      endDateMenu: false,
      isSnackbarVisible: false,
      loading: false,
      snackbarText: '',
      startDateMenu: false,
      timeout: 1500,
      valid: true,
      withSyncOptions: [{ name: 'Yes', id: 'yes' }, { name: 'No', id: 'no' }]
    }
  },

  computed: {
    ...mapFields([
      'orderReportForm',
      'responseMessage'
    ]),

    disableGenerateButton() {
      const { destinationStore, endDate, location, sourceStore, startDate, withSync } = this.orderReportForm;
      return !destinationStore && !endDate && !location && !sourceStore && !startDate && !withSync;
    },
  },

  methods: {
    showSnackBar(response) {
      this.isSnackbarVisible = true;
      this.color = response.type;
      this.snackbarText = response.message;
    },

    async generateReport(orderForm) {
      this.loading = true;
      await this.$store.dispatch('reports/generateOrderReports', orderForm);
      this.$refs.form.reset();
      this.loading = false;
      this.showSnackBar(this.responseMessage);
    },

    async generateOrderReports() {
      if(this.orderReportForm.withSync === 'yes') {
        this.orderReportForm.withSync = 1;
      } else if (this.orderReportForm.withSync === 'no') {
        this.orderReportForm.withSync = 0;
      } else {
        this.orderReportForm.withSync = null;
      }
      await this.generateReport(this.orderReportForm);
      this.showSnackBar(this.responseMessage);
    },

    async generateOrderReportsForLastMonth() {
      let orderReportForm = {
        destinationStore: null,
        endDate: null,
        location: null,
        sourceStore: null,
        startDate: null,
        withSync: null
      }
      await this.generateReport(orderReportForm);
      this.showSnackBar(this.responseMessage);
    }
  }
}
</script>

<style lang="scss">
  .reports-card {
    .v-card__title {
      font-size: 20px;
    }

    .v-btn {
      font-size: 16px;
    }

    .v-snack__content {
      font-size: 15px !important;
      text-align: center !important;
    }
  }
</style>
